import React, { useState, useEffect } from "react";
import {
  Input,
  Space,
  Result,
  Button,
  message,
  Avatar,
  Segmented,
  Descriptions,
  Typography,
  Steps,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  LeftCircleOutlined
} from "@ant-design/icons";
import MenuHeader from "../../Layout/MenuHeader";

function Quitacao() {
  const { Step } = Steps;
  const [phase1, setPhase1] = useState(1);
  const [phase4, setPhase4] = useState(null);
  const [step, setStep] = useState(0);
  const [sucesso, setSucesso] = useState(null);
  const [lista, setLista] = useState(null);
  const [nrcelular, setNrcelular] = useState(84);
  const [loading, setLoading] = useState(false); // Estado para loading
  const history = useNavigate();

  const phoneNumberPattern = /^(84|85)\d{7}$/;

  const validatePhoneNumber = (number) => {
    return phoneNumberPattern.test(number);
  };

  const Pedido_quitacao = () => {
    setLoading(true);  // Inicia o estado de loading ao clicar no botão
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/Marcar_Pedido/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1, nrcelular: nrcelular },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso inválidos");
        }
        return dat;
      })
      .then((df) => {
        setSucesso(df.data);
        setStep(3);
        setPhase1(null);
        setPhase4("sdsd");
        message.success("Pedido submetido com sucesso!");
      })
      .catch((e) => {
        message.error("Erro ao processar pagamento"); // Mensagem de erro ao falhar
      })
      .finally(() => {
        setLoading(false); // Finaliza o estado de loading
      });
  };

  const handleChange_nr = (e) => {
    const inputValue = e.target.value.trim();
    setNrcelular(inputValue);
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/dados_empresa/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1 },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso inválidos");
        }
        return dat;
      })
      .then((df) => {
        setLista(Object.entries(df.data).map(([key, value]) => ({
          key,
          label: key,
          children: value,
        })));
        message.success("Dados carregados com sucesso!");
      })
      .catch((e) => {
        message.error("Servidor indisponível");
      });
  }, []);

  const renderMpesaContent = (
    <div className="mt-3" style={{ textAlign: 'right' }}>
      <p className="text-sm mt-2">Insira o seu número de celular para efetuar o pagamento</p>
      <div>
        <Input
          placeholder="84/85 xxx xxxx"
          value={nrcelular}
          onChange={handleChange_nr}
        />
        {!validatePhoneNumber(nrcelular) && (
          <p style={{ color: 'red' }}>Número de telefone inválido. Deve começar com 84 ou 85 seguido de 7 dígitos.</p>
        )}
      </div>
      <Button
        type="primary"
        className="mt-1"
        style={{ backgroundColor: 'red' }}
        onClick={Pedido_quitacao}
        loading={loading}  // Estado de loading no botão
        disabled={!validatePhoneNumber(nrcelular)} // Desabilitar se o número for inválido
      >
        {loading ? 'Processando...' : 'Pagar'}
      </Button>
    </div>
  );

  return (
    <div className="w-full bg-white h-screen">
      <MenuHeader page={'guest'} />
      <div
        style={{
          width: "80%",
          marginTop: "2%",
          marginLeft: "10%",
          marginBottom: "2%",
        }}
      >
        <Steps current={step}>
          <Step title="Resumo" />
          <Step title="Concluído" />
        </Steps>
      </div>
      <div style={{
        width: '80%',
        marginLeft: '10%',
        backgroundColor: 'white',
        borderRadius: '8px'
      }}>
        {phase1 && (
          <Space
            className="space"
            size="large"
            align="center"
            direction="vertical"
          >
            <div
              style={{
                color: '#8A593E',
                fontWeight: '700',
                fontSize: '18px',
              }}
            >
              Certidão de Quitação
            </div>

            {lista && (
              <>
                <div className="lg:flex lg:flex-row lg:gap-8 mb-10">
                  <div className="lg:w-3/5">
                    <Descriptions title="Empresa" items={lista} />
                  </div>
                  <div className="lg:w-2/5">
                    <Typography.Title level={5}>Método de pagamento</Typography.Title>
                    <Segmented
                      options={[
                        {
                          label: (
                            <div style={{ padding: 4 }}>
                              <Avatar
                                shape="square"
                                size={64}
                                src={<img src={require("../../Assets/img/ss.png")} />}
                              />
                              <div>M-PESA</div>
                            </div>
                          ),
                          value: 0,
                        }
                      ]}
                      value={0}  // Sempre seleciona M-PESA
                      disabled  // Desabilita o Segmented para que não seja alterável
                    />
                    <div>{renderMpesaContent}</div>
                  </div>
                </div>
              </>
            )}
          </Space>
        )}

        {phase4 && (
          <div className="Registado_com_sucesso">
            <Result
              status="success"
              title="Pedido submetido com sucesso!"
              extra={[
                <Button
                  key="buy"
                  onClick={() => {
                    history("/home/Certidoes");
                  }}
                >
                  Menu
                </Button>,
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Quitacao;
